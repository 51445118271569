import { userStore } from '@context/userStore';
import Button from '@elements/buttons/Button';
import { useEffect, useState } from 'react';

export default function VerifyEmail({ setDisplayVerifyEmailMessage }) {
  const { setUserStore, ...store } = userStore();
  const [hasMounted, setHasMounted] = useState(false);

  function handleClick() {
    setUserStore(
      store.isLoggedIn,
      store.fullName,
      store.profileId,
      store.bundles,
      store.claims,
      store.items,
      store.isValidated,
      store.isActive,
      store.deletedAccount,
      store.organizationId,
      false,
      store.email,
      store.subscription,
      false,
      store.organizations,
    );
    setDisplayVerifyEmailMessage(false);
  }

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    hasMounted && (
      <div className="my-20 flex items-center justify-center">
        <div className="bg-accent-3 p-20">
          <h1 className="px-4 pb-6 font-primary text-5xl font-light text-primary-dark sm:px-0">
            Verifieer Email
          </h1>
          <div className="flex flex-col gap-2 bg-functional-contrast p-5 sm:p-6 md:p-8 lg:px-20 lg:py-8">
            <h2 className="text-xl font-bold">Welkom</h2>
            <p>
              Bedankt voor het registreren van een account bij ons! Controleer je inbox om je
              e-mailadres te verifiëren.
            </p>
            <div className="ml-auto pt-2">
              <Button onClick={handleClick} buttonType="primary" label="Ok" />
            </div>
          </div>
        </div>
      </div>
    )
  );
}
